import { Component, EventEmitter, Inject, Injector, OnInit } from '@angular/core';
import { BaseComponentDirective } from '../../../../shared/base/base.component';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {CustomerOrderUploadService} from '../services/customer-order-upload.service';

@Component({
  selector: 'app-modal-customer-order-upload',
  templateUrl: './customer-order-upload-modal.dialog.html',
})
export class CustomerOrderUploadModalDialog extends BaseComponentDirective implements OnInit {
  public result = new EventEmitter();
  public form: FormGroup;
  fileUrl: string | ArrayBuffer;
  fileListAvatar: FileList;
  fd: FormData;
  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<CustomerOrderUploadModalDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private svc: CustomerOrderUploadService
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    super.ngOnInit();
  }

  getFileChange($event: FileList): void {
    this.fd = new FormData();
    const fileTransfer = $event[0];
    this.fd.append('file', fileTransfer);

  }

  save(): void {
    this.uploadFile().then(r => {
      this.snackBar.open(this.translateService.instant('pages.audit.upload-success'), '', {duration: 5000});
      this.result.emit(r);
      this.dialogRef.close();
    }, err => {
      console.log(err);
      this.result.emit(null);
      this.snackBar.open(this.translateService.instant('pages.audit.upload-failed'), '', {duration: 5000});
    });
  }

  async uploadFile(): Promise<any> {
    return await this.svc.uploadFileXlsx(this.fd);
  }
}
