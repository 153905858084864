import {NgModule} from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';

import {AppCustomPreloader} from './preloader.class';
import {MainComponent} from './layout/main/main.component';

import { SigninLayoutComponent } from './layout/signin-layout/signin-layout.component';
import {NewLoginPageComponent} from './pages/login/new-login.page';
import {MailSentPageComponent} from './pages/login/mail-sent.page';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then((m) => m.AppDashboardModule),
  },
  {
    path: '',
    component: SigninLayoutComponent,
    children: [
      {path: 'sign-in', component: NewLoginPageComponent, pathMatch: 'full',
        data: {state: 'sign-in'}
      },
      {path: 'mail-sent', component: MailSentPageComponent, pathMatch: 'full',
        data: {state: 'forgot-password'}
      },
    ]
  },
  {
    path: 'dashboard',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/dashboard/dashboard.module').then((m) => m.AppDashboardModule),

  },
  {
    path: 'master',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/master/master.module').then((m) => m.AppMasterModule),

  },
  {
    path: 'item-group',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/item/item.module').then((m) => m.AppItemModule),

  },
  {
    path: 'supplier',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/supplier/supplier.module').then((m) => m.AppSupplierModule),

  },
  {
    path: 'customer',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/customer/customer.module').then((m) => m.AppCustomerModule),

  },
  {
    path: 'supply',
    component: MainComponent,
    loadChildren: () =>
        import('./pages/distribution/distribution.module').then((m) => m.AppDistributionModule),

  },
  {
    path: 'audit',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/audit/audit.module').then((m) => m.AppAuditModule),

  },
  {
    path: 'user',
    component: MainComponent,
    loadChildren: () =>
      import('./pages/user/user.module').then((m) => m.AppUserModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { enableTracing: false, useHash: false, preloadingStrategy: PreloadAllModules }
    )],
  exports: [RouterModule],
  providers: [AppCustomPreloader]
})
export class AppRoutingModule { }
