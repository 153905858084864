import { Directive, EventEmitter, Injector, OnInit } from '@angular/core';
import { BaseComponentDirective } from './base.component';
import { PageInterface, PageQueryInterface } from '../../interfaces/page.interface';


@Directive()
export abstract class BasePageTableComponent<T extends unknown = any> extends BaseComponentDirective implements OnInit {

  public showPages = 3;
  public totalRow = 0;
  public pageItems: T[] = [];

  public currentQuery: PageQueryInterface = null;
  public currentRawData: PageInterface<T> = null;

  protected data = new EventEmitter<PageInterface<T>>();
  protected query = new EventEmitter<PageQueryInterface>();

  protected constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._listenData();
    this._listenQuery();
  }

  public abstract requestData(query: PageQueryInterface): Promise<PageInterface<T>>;

  public changeQuery(query: PageQueryInterface): void {
    this.query.emit(query);
  }

  public reload(): void {
    this.query.emit(this.currentQuery);
  }

  /**
   * Request data to backend
   *
   * @param query
   * @protected
   */
  protected async _doRequest(query: PageQueryInterface): Promise<void> {
    if (query == null) { return; }
    const response = await this.requestData(query);
    this.data.emit(response);
  }

  /**
   * Listen Data List Change
   *
   * @protected
   */
  protected _listenData(): void {
    this.data.subscribe((res) => {
      this.currentRawData = res;
      this.pageItems = this.currentRawData?.items ?? this.currentRawData.itemDtos ?? [];
      this.totalRow = this.currentRawData.meta.totalItems;
    });
  }

  /**
   * Listen Query for Data Change
   *
   * @protected
   */
  protected _listenQuery(): void {
    this.query.subscribe((q) => {
      this._doRequest(q).then(() => {
        this.currentQuery = q;
      });
    });
  }

}
