import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { BasePageComponentDirective } from '../../../../shared/base/base-page.component';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BreadcrumbService } from '../../../../services/breadcrumb.service';
import { StockAuditMasterModel } from '../../../audit/storage-audit/models/stock-audit-list.model';
import { StorageModel } from '../../../master/storage/models/storage.model';
import { ProductModel } from '../../../master/product/models/product.model';
import { MatAccordion } from '@angular/material/expansion';
import { DialogScrollComponent } from '../../../../shared/components/dialog/dialog-scroll.component';
import { CustomerOrderUploadModalDialog } from '../modals/customer-order-upload-modal.dialog';
import {CustomerOrderUploadService} from '../services/customer-order-upload.service';

@Component({
  selector: 'app-page-customer-order-upload',
  templateUrl: './customer-order-upload.page.html',
})
export class CustomerOrderUploadPageComponent extends BasePageComponentDirective<any> implements OnInit {

  itemList: any[] = [];
  mode = 'view';
  storage: StorageModel;
  form: FormGroup;
  audit: StockAuditMasterModel;
  disableSubmit = false;
  products: ProductModel[] = [];
  productId = 0;
  f = 0;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  constructor(
    injector: Injector,
    private dialog: MatDialog,
    private breadcrumbService: BreadcrumbService,
    private svc: CustomerOrderUploadService,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<void> {
    // editor
    super.ngOnInit();
    this.breadcrumbService.changeMessage([
      {label: 'menu.customer.index', active: false},
      {label: 'pages.customer.customer-order', active: false, route: '/customer/customer-order'},
      {label: 'common.upload', active: true, route: ''}
    ]);
  }

  async ngAfterViewInit(): Promise<any> {
    setTimeout(_ => {
      this.accordion.openAll();
    }, 1500);
  }

  query(): void {

  }

  save(): void {

  }

  uploadTemplate(): void {
    const dialog = this.dialog.open(CustomerOrderUploadModalDialog, {});
    dialog.componentInstance.result.subscribe(r => {
      if (r) {
        this.itemList = r;
      }
    }, err => {
      console.log(err);
    });
  }

  downloadTemplate(): void {
    this.svc.templateById().then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'template' + '.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching image:', error);
    });
  }

  finish(): void {
    const dialog = this.dialog.open(DialogScrollComponent, {
      data: {
        title: this.translateService.instant('dialog.save-message'),
        subtitle: this.translateService.instant('dialog.save-title'),
        content: this.translateService.instant('pages.customer.submit-confirmation') ,
        buttons: [
          {
            value: 1,
            className: 'text-red btn btn-primary',
            color: 'primary',
            focus: true,
            label: this.translateService.instant('common.save')
          },
          {value: 0, className: 'text-brand-color', label: this.translateService.instant('common.close')}
        ]
      }
    });

    dialog.afterClosed().subscribe((res) => {
      if (res === 1) {
        this.disableSubmit = true;
        this.svc.submitSave(this.itemList).pipe().subscribe({complete: () => {
            this.snackBar.open(this.translateService.instant('dialog.save-success'), undefined, {
              verticalPosition: 'top', horizontalPosition: 'end', duration: 3000
            });
            this.router.navigate(['/customer/order']).then();
          }, error: (err) => {
            this.disableSubmit = false;
            console.log(err);
          }});
      }
    });
  }
}
