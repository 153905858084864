<div class="white-background p-5">

  <mat-dialog-content style="width: 600px;">
    <div class="row mt-3">
      <div class="col-12">
        <app-upload-single
          [readonly]="readonly"
          [fileUrl]="fileUrl"
          [accept]="'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'"
          [label]="'common.accepted-format-xlsx' | translate"
          [labelButton]="'pages.customer.upload-customer-order-xlsx' | translate"
          (fileChange)="getFileChange($event)"
        >

        </app-upload-single>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions  class="justify-content-end pe-4">
    <button mat-raised-button class="py-1" (click)="save()" color="primary">{{'common.upload' | translate}}</button>
    <button type="button" mat-dialog-close mat-stroked-button class="py-1 white-background text-black-50">{{'common.close' | translate}}</button>
  </mat-dialog-actions>

</div>
