import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '../../material/material.module';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgProgressModule} from 'ngx-progressbar';
import {AppSharedModule} from '../../shared/shared.module';
import {LoginService} from '../../services/login.service';
import {DashboardPageComponent} from './pages/dashboard.page';
import {DashboardRoutesModule} from './dashboard-routes.module';
import { CriticalStockPageComponent } from './pages/critical-stock.page';
import { AppComponentModule } from '../../shared/components/component.module';
import {NgxChartsModule} from '@swimlane/ngx-charts';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgProgressModule,
    AppSharedModule,
    DashboardRoutesModule,
    AppComponentModule,
    NgxChartsModule
  ],
  exports: [
    DashboardPageComponent,
    CriticalStockPageComponent
  ],
  declarations: [
    DashboardPageComponent,
    CriticalStockPageComponent
  ],
  providers: [
    LoginService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppDashboardModule {}
