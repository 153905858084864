import {Injectable} from '@angular/core';
import {BasePageService} from '../../../../shared/base/base-page.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {BACKOFFICE, CUSTOMER_ORDER_BASE_URL, STOCK_AUDIT_BASE_URL} from '../../../../constants/api.constant';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CustomerOrderUploadService extends BasePageService<any> {

  detailUrl = environment.url + BACKOFFICE + CUSTOMER_ORDER_BASE_URL;

  get baseUrl(): string {
    return environment.url + BACKOFFICE + STOCK_AUDIT_BASE_URL;
  }

  constructor(public http: HttpClient) {
    super(http);
  }

  async templateById(): Promise<any> {
    return await this.http.get<any>(this.detailUrl + `/order-template/xlsx`, {
      responseType: 'blob' as 'json'
    }).toPromise();
  }

  async uploadFileXlsx(fd: FormData): Promise<any> {
    return await this.http.post<any>(this.detailUrl + `/order-template-upload/xlsx`,
      fd, {}).toPromise();
  }

  submitSave(data: any): Observable<any> {
    return this.http.post(this.detailUrl + '/order-template-save/xlsx', data);
  }
}
