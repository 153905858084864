<div class="main-content homepage grey-background">
    <div class="container-fluid">
        <div class="mat-card m- white-background text-black-50 rounded-lg">
          <div class="mat-card-title px-2 py-3">
            <h3 class="pl-2">{{'menu.dashboard.index' | translate}}</h3>
          </div>
          <div class="mat-card-content p-3 rounded">
            <div class="row">
              <div class="col-4">
                <mat-card style="box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;">
                  <mat-card-header>
                    <mat-card-title>
                      <h4>Monthly Order</h4>
                    </mat-card-title>
                  </mat-card-header>

                  <mat-card-content>
                    <div class="text-end"><h1>{{monthlyOrder.length}}</h1> invoices</div>
                  </mat-card-content>
                  <mat-card-footer class="mx-1 py-2">
                    <span style="font-size: 10px;">Last Month Order: {{lastMonthOrder.length}}</span>
                  </mat-card-footer>
                </mat-card>
              </div>
              <div class="col-4">
                <mat-card style="box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;">
                  <mat-card-header>
                    <mat-card-title>
                      <h4>Today Order</h4>
                    </mat-card-title>
                  </mat-card-header>

                  <mat-card-content>
                      <div class="text-end"><h1>{{todayOrder.length}}</h1> invoices</div>
                  </mat-card-content>
                  <mat-card-footer class="mx-1 py-2">
                    <span style="font-size: 10px;">&nbsp;</span>
                  </mat-card-footer>
                </mat-card>
              </div>
              <div class="col-4">
                <mat-card style="box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;">
                  <mat-card-header>
                    <mat-card-title>
                      <h4>Paid Invoices</h4>
                    </mat-card-title>
                  </mat-card-header>

                  <mat-card-content>
                    <div class="text-end"><h1>{{monthlyPaidOrder.length}}</h1> invoices</div>
                  </mat-card-content>
                  <mat-card-footer class="mx-1 py-2">
                    <span style="font-size: 10px;">Last Month Paid Order: {{lastMonthPaidOrder.length}}</span>
                  </mat-card-footer>
                </mat-card>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-6">
                <mat-card style="box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;">
                  <mat-card-header>
                    <mat-card-title>
                      <h4>Customer Graphic</h4>
                    </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                  </mat-card-content>
                </mat-card>
              </div>

            </div>

            <div class="row">
              <div class="col-12">
                <mat-card style="box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;">
                  <mat-card-content>
                    <div class="row">
                      <div class="col-6 pt-1">
                        <app-select
                          (valueChange)="changeCriticalStock($event)"
                          [readonly]="readonly"
                          [items]="storages"
                          [bindLabel]="'storageName'"
                          [bindValue]="'id'"
                          [label]="'pages.dashboard.critical-stock-storage' | translate"
                          [(ngModel)]="storageName"></app-select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="table-responsive">
                          <table class="table table-striped table-hover">
                            <thead>
                            <tr>
                              <th style="font-size: 11px;">{{ 'pages.item-group.item-group-code' | translate }}</th>
                              <th style="font-size: 11px;">{{ 'pages.item.name' | translate }}</th>
                              <th style="font-size: 11px;">{{ 'pages.product.product-name' | translate }}</th>
                              <th style="font-size: 11px;">{{ 'pages.product-sub.name' | translate }}</th>
                              <th class="text-end" style="font-size: 11px;">{{ 'pages.item.stock' | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let i of pageItems">
                              <td style="font-size: 11px;">{{ i.itemGroupCode }}</td>
                              <td style="font-size: 11px;">{{ i.itemGroupName }}</td>
                              <td style="font-size: 11px;">{{ i.productName }}</td>
                              <td style="font-size: 11px;">{{ i.subProductName }}</td>
                              <td class="text-end" style="font-size: 11px;">{{i.currentStock}}</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                              <td colspan="5" class="text-end">
                                <button (click)="printAll()" type="button"  mat-button color="primary">
                                  {{ 'common.print-all' | translate }}
                                </button>
                                <button (click)="viewAll()" type="button"  mat-button color="warn">
                                  {{ 'common.view-all' | translate }}
                                </button>

                              </td>
                            </tr>
                            </tfoot>
                          </table>
                        </div>
                      </div>
                    </div>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
