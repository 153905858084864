import * as moment from 'moment';
import {
  APP_DATE_FORMAT,
  DB_DATE_FORMAT,
  DB_TIMESTAMP_FORMAT,
  APP_DATETIME_COMPLETE_FORMAT,
  MONTH_LIST_MULTI_LANG
} from '../constants/common.constant';
import {LocaleMonthInterface} from '../interfaces/common.interface';
import {Moment} from 'moment';

export class DateHelper {
  static remainingDate(dateStart): number {
    const startDate   = moment(dateStart, DB_DATE_FORMAT);
    const currentDate = moment().format(DB_DATE_FORMAT);
    return parseInt(startDate.diff(currentDate, 'days').toString(), 10);
  }
  static localeDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format(APP_DATE_FORMAT).toString();
  }
  static completeLocaleDateTime(date: string, locale: string): string {
    return moment(date, DB_TIMESTAMP_FORMAT).locale(locale).format(APP_DATETIME_COMPLETE_FORMAT).toString();
  }
  static localeDateFromTs(date: string, locale: string): string {
    return moment(date, DB_TIMESTAMP_FORMAT).locale(locale).format(APP_DATE_FORMAT).toString();
  }
  static monthOnlyFromDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format('MMMM').toString();
  }
  static dateOnlyFromDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format('D').toString();
  }
  static dayOnlyFromDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format('dddd').toString();
  }
  static yearOnlyFromDate(date: string, locale: string): string {
    return moment(date, DB_DATE_FORMAT).locale(locale).format('YYYY').toString();
  }
  static dateTimeToLocaleDate(date: string, locale: string): string {
    return moment(date, DB_TIMESTAMP_FORMAT).locale(locale).format(APP_DATE_FORMAT).toString();
  }
  static toDbDate(date: string): any {
    return moment(date).format(DB_DATE_FORMAT).toString();
  }
  static momentToDbDate(date: Moment): any {
    return date.format(DB_DATE_FORMAT).toString();
  }
  static nowToDbDate(): any {
    return moment().format(DB_DATE_FORMAT).toString();
  }
  static toDatePicker(date: string): any {
    return moment(date, DB_DATE_FORMAT);
  }
  static localeMonthFormat(month: number): LocaleMonthInterface {
    const m: LocaleMonthInterface[] = MONTH_LIST_MULTI_LANG as LocaleMonthInterface[];
    return m.find((x) => x.month === month);
  }
  static getCurrentMonth(): number {
    return parseInt(moment().month().toString(), 10);
  }
  static getCurrentYear(): number {
    return parseInt(moment().year().toString(), 10);
  }
  static getCurrentDateTime(locale: string): string {
    return moment().locale(locale).format(APP_DATETIME_COMPLETE_FORMAT);
  }
  static excelSerialToDate(serial): any {
    const excelStartDate = new Date(Date.UTC(1899, 11, 30)); // Use UTC to avoid timezone issues
    const date = new Date(excelStartDate.getTime() + serial * 86400000);
    date.setUTCHours(0, 0, 0, 0); // Normalize to UTC
    return date.toISOString().split('T')[0]; // Returns YYYY-MM-DD
  }
  static excelSerialToDate2(serial): any {
    const excelStartDate = new Date(Date.UTC(1899, 11, 30));
    const date = new Date(excelStartDate.getTime() + serial * 86400000);
    date.setUTCHours(0, 0, 0, 0);

    const day = date.getUTCDate();
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getUTCMonth()]; // Get month name
    const year = date.getUTCFullYear();

    return `${month}, ${day} ${year}`;
  }
  static carbonToMoment(date: string, locale: string): any {
    const dateAndTime = date.split(' ');
    const timeAndTz = dateAndTime[1].split('.');
    return {
      // tslint:disable-next-line:max-line-length
      completeDateTime: moment(dateAndTime[0] + ' ' + timeAndTz[0], DB_TIMESTAMP_FORMAT).locale(locale).format(APP_DATETIME_COMPLETE_FORMAT).toString(),
      completeDate:  moment(dateAndTime[0], DB_DATE_FORMAT).locale(locale).format(APP_DATE_FORMAT).toString(),
      date: dateAndTime[0],
      time: timeAndTz[0]
    };
  }
}
