<div class="main-content homepage grey-background">
  <div class="container-fluid">
    <div class="mat-card m- white-background text-black-50">

      <div class="mat-card-content px-3 mt-3 py-5">

        <mat-accordion class="example-headers-align" multi >

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{'pages.customer.customer-order' | translate}} {{'common.upload' | translate}}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row mt-3">
              <div class="col-12">
                <div class="row">
                  <div class="col-3">


                  </div>
                  <div class="col-3">

                  </div>
                  <div class="col-6 pt-4 justify-content-end text-end">
                    <button
                      type="button"
                      (click)="downloadTemplate()"
                      class="btn btn-success text-white me-2">{{'pages.audit.download-template-xlsx' | translate}}</button>

                    <button
                      type="button"
                      (click)="uploadTemplate()"
                      mat-flat-button
                      color="primary"
                      class="text-white me-2">{{'pages.audit.upload-template-xlsx' | translate}}</button>

                  </div>
                </div>
              </div>
            </div>


            <div class="row mt-3">
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table table-striped table-hover table-bordered">
                    <tbody>
                    <ng-container *ngFor="let d of itemList;  index as i">
                    <tr>
                      <td class="text-nowrap"><b>Customer ID:</b> {{d.CUSTOMER_ID}}</td>
                      <td class="text-nowrap"><b>Customer Name:</b> {{d.CUSTOMER_NAME}}</td>
                      <td class="text-nowrap"><b>Directorate:</b> {{d.DIRECTORATE}}</td>
                      <td class="text-nowrap"><b>Order Date:</b> {{date.excelSerialToDate2(d.ORDER_DATE)}}</td>
                      <td class="text-nowrap"><b>Delivery Date:</b> {{date.excelSerialToDate2(d.DELIVERY_DATE)}}</td>
                    </tr>
                    <tr>
                      <td [colSpan]="5">
                        <table class="table table-striped table-hover table-bordered">
                          <thead>
                          <tr>
                            <th class="text-nowrap">SKU</th>
                            <th class="text-nowrap">Item Name</th>
                            <th class="text-nowrap">Unit</th>
                            <th class="text-nowrap text-end">Current Stock</th>
                            <th class="text-nowrap text-end">Remind Stock When</th>
                            <th class="text-nowrap text-end">Qty</th>
                            <th class="text-nowrap text-end">Final Qty</th>
                            <th class="text-nowrap text-end">Buy Price</th>
                            <th class="text-nowrap text-end">Standard Price</th>
                            <th class="text-nowrap text-end">Sell Price</th>
                            <th class="text-nowrap text-end">Subtotal</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let dx of d.items;  index as j">
                            <td class="bg-brand {{dx.SKU != undefined ? '':'bg-red-smooth'}}">{{dx.SKU}}</td>
                            <td class=" {{dx.ITEM_NAME != undefined ? '':'bg-red-smooth'}}">{{dx.ITEM_NAME}}</td>
                            <td class=" {{dx.UNIT != undefined ? '':'bg-red-smooth'}}">{{dx.UNIT}}</td>
                            <td class="text-end {{dx.STOCK != undefined ? '':'bg-red-smooth'}}">{{dx.STOCK}}</td>
                            <td class="text-end {{dx.REMIND_STOCK != undefined ? '':'bg-red-smooth'}}">{{dx.REMIND_STOCK}}</td>
                            <td class="text-end {{dx.QTY != undefined ? '':'bg-red-smooth'}}">{{dx.QTY}}</td>
                            <td class="text-end {{dx.FINAL_QTY != undefined ? '':'bg-red-smooth'}}">{{dx.FINAL_QTY}}</td>
                            <td class="text-end {{dx.BUY_PRICE != undefined ? '':'bg-red-smooth'}}">{{dx.BUY_PRICE | number:'1.2-2'}}</td>
                            <td class="text-end {{dx.STANDARD_PRICE != undefined ? '':'bg-red-smooth'}}">{{dx.STANDARD_PRICE | number:'1.2-2'}}</td>
                            <td class="text-end {{dx.SELL_PRICE != undefined ? '':'bg-red-smooth'}}">{{dx.SELL_PRICE | number:'1.2-2'}}</td>
                            <td class="text-end {{dx.SUBTOTAL != undefined ? '':'bg-red-smooth'}}">{{dx.SUBTOTAL | number:'1.2-2'}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td [colSpan]="5" class="text-nowrap text-end" style="background: #333 !important;">
                        <b class="text-weight-bolder" style="font-weight: bolder !important; color: #fff !important;">GRAND TOTAL: {{d.GRAND_TOTAL | number:'1.2-2'}}</b>
                      </td>
                    </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>



            <div class="row mt-3">
              <div class="col-12 justify-content-end text-end">
                <button *ngIf="itemList.length > 0"
                        (click)="finish()"
                        [disabled]="itemList.length <= 0"
                        type="button"
                        mat-raised-button color="primary">
                  {{'pages.customer.submit' | translate}}
                </button>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>

      </div>
    </div>
  </div>
</div>
